/**
 * @returns {number} The height of the window
 */
const getScreenHeight = () => {
    return window.innerHeight;
};

/**
 * @returns {number} The height of the full page
 */
const getPageHeight = () => {
    const body = document.body,
        html = document.documentElement;

    return Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
    );
};

/**
 * @returns {number} The width of the window
 */
const getScreenWidth = () => {
    return window.innerWidth;
};

/**
 * @param {*} element
 * @returns element offset relative to window top
 */
const getTopOffset = (element) => {
    if (typeof input === 'string') {
        element = document.querySelector(element);
    }

    let offsetTop = 0;
    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
};




/**
 *  detects if mobile operating system is IOS
 *  @returns {boolean} 
 */


const isMobileSystemIOS = () => {
    var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    var isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)
        ? true
        : false;
    var isIOS = navigator.platform.match(/(iPhone|iPod|iPad)/i) ? true : false;
    if (isMac || isMacLike || isIOS) {
        return true;
    }
    return false;
}

/**
 * Serves to fix body height on iOS when toggling body freeze
 */

const syncBodyHeight = () => {
    document.documentElement.style.setProperty(
        "--window-inner-height",
        `${window.innerHeight}px`
    );
}

function getScrollbarWidth() {
    // Create a temporary div element
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // Force scrollbar to appear
    document.body.appendChild(outer);

    // Create an inner div and append it to the outer div
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculate the scrollbar width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Remove the temporary divs from the document
    document.body.removeChild(outer);

    return scrollbarWidth;
}

const scrollbarWidth = getScrollbarWidth();

/**
 * Freezes and unfreezes body when a modal is opened, cross/browser functionality.
 * reuqires freeze-body-ios and freeze-body classes
 */

const freezeBody = () => {
    let body = document.body;
    if (isMobileSystemIOS()) {
        body.classList.add("freeze-body-ios");
        window.addEventListener("resize", syncBodyHeight);
    } else {
        body.classList.add("freeze-body");
        document.documentElement.style.overflow = "hidden";
    }
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.querySelector('header').style.paddingRight = `${scrollbarWidth}px`;
}

const unfreezeBody = () => {
    let body = document.body;
    if (isMobileSystemIOS()) {
        body.classList.remove("freeze-body-ios");
        window.removeEventListener("resize", syncBodyHeight);
    } else {
        body.classList.remove("freeze-body");
        document.documentElement.style.overflow = "unset";
    }
    document.body.style.paddingRight = null;
    document.querySelector('header').style.paddingRight = null;
}



/**
 * Check whether the specified element is in view within the viewport.
 *
 * @param {HTMLElement|string} element - The target element or CSS selector of the target element.
 * @param {number} [minArea=-1] - The minimum area of the element that must be visible.
 * @param {string} [areaUnit='%'] - The unit of the minArea parameter ('%', 'px', or 'vh').
 * @returns {boolean} - Returns true if the specified minimum area of the element is visible in the viewport.
 */
const isInView = (element, minArea = -1, areaUnit = '%') => {
    const screenTop = window.scrollY;
    const screenBottom = getScreenHeight() + screenTop;

    if (typeof element === 'string') {
        element = document.querySelector(element);
    }

    const top = getTopOffset(element);
    const elementHeight = element.offsetHeight;
    const bottom = top + elementHeight;

    // Case where we just check if any part of the element is in view
    if (minArea == -1) {
        return bottom > screenTop && top < screenBottom;
    }

    // Calculate the visible pixels and percentage of the element that is in view
    const visiblePixels = Math.max(
        0,
        Math.min(bottom, screenBottom) - Math.max(top, screenTop)
    );
    const visiblePercentage = (visiblePixels / elementHeight) * 100;

    // Determine if the element is in view based on the specified minArea and areaUnit
    switch (areaUnit) {
        case '%':
            return visiblePercentage >= minArea;
        case 'px':
            return visiblePixels >= minArea;
        case 'vh':
            return visiblePixels >= minArea * (getScreenHeight() / 100);
        default:
            return visiblePixels >= minArea;
    }
};

/**
 * Cap a value between min and max
 * @param {*} min Minimum value
 * @param {*} max Maximum value
 * @param {*} val Number to cap
 * @returns {number} Capped number
 */
const minMax = (min, max, val) => {
    return Math.min(Math.max(val, min), max);
};

/**
 * Scrolls window to given offset ( Similar functionality to scrolling to anchor, but without the anchor )
 * @param {*} endOffset The target top offset (y pos)
 * @param {*} time The time it takes to scroll to the target offset
 * @param {*} onFinish Callback function that is called when the scroll is finished
 */
const easedScroll = async (endOffset, time = 1200, onFinish = () => { }) => {
    const startOffset = window.scrollY;

    Easing.ease({
        from: startOffset,
        to: Math.max(
            0,
            Math.min(getPageHeight() - getScreenHeight(), endOffset)
        ),
        time,
        onFinish,
        curve: Easing.curves.easeInOut,
        onUpdate: (calculatedProgress) => {
            window.scroll({ top: calculatedProgress, behavior: 'instant' });
        },
    });
};

/**
 * The Easing class provides methods for easing animations with various curves.
 * Easing animations create a more natural and pleasing visual effect by gradually accelerating and decelerating.
 */
class Easing {
    /**
     * A set of predefined easing curves based on common mathematical equations. You can find more at https://easings.net/
     */
    static curves = {
        linear: (x) => x,
        easeInOut: (x) =>
            x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
        easeInOutSine: (x) => {
            return -(Math.cos(Math.PI * x) - 1) / 2;
        },
    };

    /**
     * Eases a value from 0 to 1 over the specified time using the specified easing curve.
     * @param {Object} param0 - Configuration object for the easing.
     * @param {function} param0.curve - The easing curve function to use. Default is linear.
     * @param {number} param0.time - The total time for the easing in milliseconds. Default is 1000 ms.
     * @param {function} param0.onUpdate - Callback function called on each update with the eased value.
     * @param {function} param0.onFinish - Callback function called when the easing is finished with the final eased value.
     */
    static easeRaw({
        curve = this.curves.linear,
        time = 1000,
        onUpdate = () => { },
        onFinish = () => { },
    }) {
        const start = Date.now();
        let lastProgress = 0;
        const intId = setInterval(() => {
            const now = Date.now();
            const progress = (now - start) / time;
            const calculatedProgress = curve(Math.min(1, progress));
            if (lastProgress >= 1 && progress > 1) {
                onFinish(calculatedProgress);
                clearInterval(intId);
                return;
            }
            onUpdate(calculatedProgress);
            lastProgress = progress;
        }, 20);
    }

    /**
     * Eases a value from a specified starting point to an ending point over the specified time using the specified easing curve.
     * @param {Object} param0 - Configuration object for the easing.
     * @param {number} param0.from - The starting value for the easing. Default is 0.
     * @param {number} param0.to - The ending value for the easing. Default is 100.
     * @param {function} param0.curve - The easing curve function to use. Default is linear.
     * @param {number} param0.time - The total time for the easing in milliseconds. Default is 1000 ms.
     * @param {function} param0.onUpdate - Callback function called on each update with the eased value and the change in value.
     * @param {function} param0.onFinish - Callback function called when the easing is finished with the final eased value and the change in value.
     */
    static ease({
        from = 0,
        to = 100,
        curve = this.curves.linear,
        time = 1000,
        onUpdate = () => { },
        onFinish = () => { },
    }) {
        const dir = to > from ? 1 : -1;
        const distance = Math.abs(from - to);
        let lastProgress = from;
        const callProgress = (callback, progress) => {
            const calculatedProgress = from + distance * progress * dir;
            const moved = calculatedProgress - lastProgress;
            callback(calculatedProgress, moved);
            lastProgress = calculatedProgress;
        };
        this.easeRaw({
            curve,
            time,
            onUpdate: (progress) => {
                callProgress(onUpdate, progress);
            },
            onFinish: (progress) => {
                callProgress(onFinish, progress);
            },
        });
    }

    /**
     * Eases multiple values from specified starting points to ending points over the specified time using the specified easing curve.
     * @param {Object} param0 - Configuration object for the easing.
     * @param {Object} param0.all - Object where each key represents a value to be eased with a from and to property specifying the start and end points.
     * @param {function} param0.curve - The easing curve function to use. Default is linear.
     * @param {number} param0.time - The total time for the easing in milliseconds. Default is 1000 ms.
     * @param {function} param0.onUpdate - Callback function called on each update with an object containing the eased values and the changes in values.
     * @param {function} param0.onFinish - Callback function called when the easing is finished with an object containing the final eased values and the changes in values.
     */
    static easeAll({
        all,
        curve = this.curves.linear,
        time = 1000,
        onUpdate = () => { },
        onFinish = () => { },
    }) {
        let options = {};
        Object.entries(all).forEach(([key, value]) => {
            const { from, to } = value;
            options[key] = {
                ...value,
                dir: to > from ? 1 : -1,
                distance: Math.abs(from - to),
                lastProgress: from,
            };
        });
        const callProgress = (callback, progress) => {
            const ret = {};
            Object.entries(options).forEach(([key, value]) => {
                const { from, dir, distance, lastProgress } = value;
                const calculatedProgress = from + distance * progress * dir;
                const moved = calculatedProgress - lastProgress;
                ret[key] = {
                    progress: calculatedProgress,
                    moved,
                };
                value.lastProgress = calculatedProgress;
            });
            callback(ret);
        };
        this.easeRaw({
            curve,
            time,
            onUpdate: (progress) => {
                callProgress(onUpdate, progress);
            },
            onFinish: (progress) => {
                callProgress(onFinish, progress);
            },
        });
    }
}

/**
 * This class is responsible for managing drag movements on a given element, detected through touch and mouse events.
 * It provides callbacks for various stages of the drag movement (start, move, end) with useful data about the movement, like speed, distance traveled etc.
 */
class DragMovementManager {
    touching = false; // A flag indicating if a drag movement is in progress
    lastTouch = null; // Stores the last touch or mouse position during a drag movement
    lastMove = null; // Stores the timestamp of the last move event during a drag movement
    lastDist = null; // Stores the last calculated distance moved during a drag

    firstTouch = null; // Stores the initial touch or mouse position at the start of a drag movement
    startTime = null; // Stores the timestamp at the start of a drag movement
    totalTime = null; // Stores the total time elapsed during a drag movement
    totalDist = null; // Stores the total distance moved during a drag movement

    tempDisabled = false; // Temporary disables the drag movement functionality

    /**
     * Initializes a new instance of the DragMovementManager.
     *
     * @param {string} selector - The CSS selector for the target element that should respond to drag movements.
     * @param {object} param1 - An object containing the callbacks that will be invoked on different drag events.
     * @param {function} param1.touchStart - Callback invoked when a drag movement starts.
     * @param {function} param1.touchEnd - Callback invoked when a drag movement ends, it receives an object with details about the movement.
     * @param {function} param1.move - Callback invoked during a drag movement, it receives an object with details about the current state of the movement.
     */
    constructor(
        selector,
        { touchStart = () => { }, touchEnd = () => { }, move = () => { } }
    ) {
        this.el = document.querySelector(selector); // The DOM element associated with the given selector
        this.callback = {
            move, // The callback to be invoked on move events
            touchStart, // The callback to be invoked on touch/mouse start events
            touchEnd, // The callback to be invoked on touch/mouse end events
        };

        this.initListeners(); // Setting up the event listeners for drag movements
    }

    /**
     * Sets up the necessary event listeners for detecting drag movements on the element.
     */
    initListeners() {
        // Adding event listeners for the start, move, and end phases of drag movements, for both touch and mouse inputs
        this.el.addEventListener('mousedown', (e) => this.start(e));
        this.el.addEventListener('touchstart', (e) => this.start(e), {
            passive: true,
        });

        this.el.addEventListener('mousemove', (e) => this.move(e));
        this.el.addEventListener('touchmove', (e) => this.move(e), {
            passive: true,
        });

        this.el.addEventListener('mouseleave', () => this.end());
        this.el.addEventListener('mouseup', () => this.end());
        this.el.addEventListener('touchend', () => this.end());
    }

    /**
     * Handler for move events during a drag movement. It calculates the current speed and distance traveled and invokes the move callback with this data.
     *
     * @param {Event} e - The event object from the move event.
     */
    move(e) {
        if (!this.touching || this.tempDisabled) return;

        const currentTouch = {
            x: e.pageX || e.touches[0].pageX,
            y: e.pageY || e.touches[0].pageY,
        };

        if (currentTouch && this.lastTouch && this.lastMove) {
            const dist = {
                x: currentTouch.x - this.lastTouch.x,
                y: currentTouch.y - this.lastTouch.y,
            };
            this.speed =
                Math.abs(dist.x) / ((this.lastMove - Date.now()) / 1000);
            this.lastDist = dist;

            if (Math.abs(dist.x) > Math.abs(dist.y)) {
                this.totalDist = {
                    x: currentTouch.x - this.firstTouch.x,
                    y: currentTouch.y - this.firstTouch.y,
                };
                this.callback.move({
                    totalDist: this.totalDist,
                    dist,
                    speed: Math.abs(this.speed),
                });
            }
        }

        this.lastMove = Date.now();
        this.lastTouch = currentTouch;
    }

    /**
     * Handler for start events of a drag movement. It stores the initial touch/mouse position and time, and invokes the touchStart callback.
     *
     * @param {Event} e - The event object from the start event.
     */
    start(e) {
        this.startTime = Date.now();
        this.callback.touchStart();
        this.touching = true;
        this.lastTouch = {
            x: e.pageX || e.touches[0].pageX,
            y: e.pageY || e.touches[0].pageY,
        };

        this.firstTouch = this.lastTouch;
    }

    /**
     * Handler for end events of a drag movement. It calculates the total speed and distance traveled, and invokes the touchEnd callback with this data.
     * It also resets the necessary properties for detecting subsequent drag movements.
     */
    end() {
        if (!this.touching) return;

        this.callback.touchEnd({
            tempDisabled: this.tempDisabled,
            speed: Math.abs(this.speed),
            totalSpeed: this.totalDist
                ? Math.abs(
                    this.totalDist.x / ((Date.now() - this.startTime) / 1000)
                )
                : 0,
            dist: this.lastDist,
            totalDist: this.totalDist,
            totalTime: Date.now() - this.startTime,
        });

        this.touching = false;
        this.tempDisabled = false;
    }
}

/**
 * Scrolls to give anchor
 * @param {*} anchor Element selector
 */
const scrollToAnchor = (anchor) => {
    const target = document.querySelector(anchor);

    if (!target) {
        return;
    }

    const offset =
        getTopOffset(target) -
        (parseInt(
            window.getComputedStyle(target).paddingTop.replace('px', '')
        ) || 100);

    easedScroll(offset, 1200, () => {
        customEvents.callAll({}, 'anchorScrollEnd');
    });
};

/**
 * Override default anchor scroll at link click
 */
const anchors = () => {
    document.querySelectorAll(`a[href*="#"]`).forEach((el) => {
        var url = el.getAttribute('href');

        var page = url.split('#').shift();

        if (
            !(page.trim() == '' || page == window.location.href.split('#')[0])
        ) {
            return;
        }

        el.addEventListener('click', (e) => {
            e.preventDefault();

            history.pushState(null, '', `${url}`);

            if (url.includes('#') && url.split('#').pop().length > 0) {
            }

            if (url !== '#')scrollToAnchor(`#${url.split('#').pop()}`);
        });
    });

    let l = window.location.href;
    if (l.includes('#') && l.split('#').pop().length > 0) {
        scrollToAnchor(`#${l.split('#').pop()}`);
    }
};

/**
 * Adds root css variable with current screen height
 */
const screenHeight = () => {
    document
        .querySelector(':root')
        .style.setProperty('--screen-h', `${getScreenHeight()}px`);
};

/**
 * CustomEventsObject is a utility class for managing custom events and their handlers.
 */
const customEvents = new CustomEventsObject();
function CustomEventsObject() {
    // An object containing arrays of functions registered to various events.
    this.functions = { default: [] };
    // An object containing timing information for various events.
    this.times = { default: { last: -1, timeout: 0 } };

    // Set a timeout for an event.
    this.setTimeout = function (timeout, event = 'default') {
        // Initialize the event if it doesn't already exist.
        if (!this.functions[event]) {
            this.functions[event] = [];
            this.times[event] = { last: -1, timeout: 0 };
        }
        // Set the timeout duration for the event.
        this.times[event].timeout = timeout;
    };

    // Register a new function to an event.
    this.register = function (func, event = 'default') {
        // Initialize the event if it doesn't already exist.
        if (!this.functions[event]) {
            this.functions[event] = [];
            this.times[event] = { last: -1, timeout: 0 };
        }

        // Check if the argument is a function.
        if (typeof func === 'function') {
            this.functions[event].push(func);
        } else {
            // console.error('The provided argument is not a function');
        }
    };

    // Call all registered functions for an event with a given argument.
    this.callAll = function (arg, event = 'default') {
        // Exit if the event doesn't exist.
        if (!this.functions[event]) return;

        const timeout = this.times[event].timeout;
        const last = this.times[event].last;

        // Check if enough time has elapsed since the last function call.
        if (Date.now() - last < timeout) {
            return;
        }

        // Update the last function call time and call all registered functions.
        this.times[event].last = Date.now();
        this.functions[event].forEach((func) => {
            func(arg);
        });
    };
}

/**
 * Init all events / functions that need setup
 */
const initUtils = () => {
    anchors();

    screenHeight();
    window.addEventListener('resize', screenHeight);
};

export {
    initUtils,
    getScreenHeight,
    getScreenWidth,
    getTopOffset,
    easedScroll,
    isInView,
    minMax,
    scrollToAnchor,
    customEvents,
    Easing,
    DragMovementManager,
    isMobileSystemIOS,
    freezeBody,
    unfreezeBody
};
