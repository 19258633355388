import { freezeBody, unfreezeBody } from '../utils';

const initMobileMenu = () => {
    const mobileMenu = document.querySelector('.js-menu-mobile');

    const closers = document.querySelectorAll('.js-menu-hide');

    closers.forEach(element => {
        element.addEventListener('click', () => {
            mobileMenu.style.transform = "translateX(100%)";
            mobileMenu.ariaExpanded = "false";

            unfreezeBody()
        });
    });

    document.querySelector('.js-menu-show').addEventListener('click', () => {
        mobileMenu.style.transform = "translateX(0%)";
        mobileMenu.ariaExpanded = "true";
        freezeBody();
    });

    // HIDE STICKY MENU IF SCROLLING TO AN ANOCHR ON SCROLLEND
    function onScrollEnd() {
        //console.log("the scroll is over")
        menu.classList.add('is-away');
        // Remove the event listener
        window.removeEventListener("scrollend", onScrollEnd);
    }
    const mobileMenulinks = document.querySelectorAll('.js-mobile-menu-link');
    mobileMenulinks.forEach(element => {
        element.addEventListener('click', () => { window.addEventListener("scrollend", onScrollEnd) });
    });
};

export { initMobileMenu }