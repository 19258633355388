import Glide from '@glidejs/glide';


const initProfessionSliders = () => {

    const setupBlock = (block) => {

        const slider = block.querySelector(".glide");
        if (!slider) return;

        let options = {
            type: 'slider',
            rewind: false,
            autoplay: false,
            touchRatio: 0.2,
            touchAngle: 30,
            swipeTreshold: 40,
            dragTreshold: 60,
            animationDuration: 1000,
            perView: 1,
            peek: { before: 51, after: 350 },
            gap: 51,
            breakpoints: {
                767: {
                    peek: { before: 35, after: 200 },
                    gap: 45,
                },
                639: {
                    peek: { before: 35, after: 80 },
                    gap: 45,
                },
            }
        }
    
        const glide = new Glide(slider, options);

        /* setup control arrows and buttons */

        const arrowLeft  = block.querySelector(".js-left");
        const arrowRight = block.querySelector(".js-right");

        if (arrowLeft) {
            arrowLeft.addEventListener("click", () => {
                glide.go(`<`);
            })
        }

        if (arrowRight) {
            arrowRight.addEventListener("click", () => {
                glide.go(`>`);
            })
        }



        glide.on(['mount.before', 'run'], (event) => {

            let slidesCount = slider.querySelectorAll('.glide__slide').length;

            if (glide.index == 0) {
                arrowLeft.classList.add('is-disabled');
            }
            else {
                arrowLeft.classList.remove('is-disabled');
            }

            if (glide.index == slidesCount-1) {
                arrowRight.classList.add('is-disabled');
            }
            else {
                arrowRight.classList.remove('is-disabled');
            }
        })


        /* change slider cursor */

        slider.addEventListener('mousedown', () => { slider.style.cursor = 'grabbing' })
        slider.addEventListener('mouseup', () => { slider.style.cursor = 'grab' })
        slider.addEventListener('mouseenter', () => { slider.style.cursor = 'grab' })

        /* mount */

        glide.mount();
    }

    let blocks = document.querySelectorAll(".js-profession-slider");

    if (!blocks) return;
    
    blocks.forEach(block => {
        setupBlock(block);
    });
    
}

export { initProfessionSliders }
