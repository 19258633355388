import { isInView } from './utils'

var duration = 3000;

const countUp = (el, from, to) => {
    var startTime = performance.now();

    function formatNumber(n) {
        if (n >= 1000000000) {
            return (n / 1000000000).toFixed(0) + '&nbsp;mld.';
        } else if (n >= 1000000) {
            return (n / 1000000).toFixed(0) + '&nbsp;mil.';
        } else {
            return n.toString();
        }
    }

    function updateCounter() {
        var now = performance.now();
        var rawProgress = (now - startTime) / duration; // calculate raw progress as a fraction between 0 and 1
        var progress = rawProgress < 1 ? 1 - (1 - rawProgress) * (1 - rawProgress) : 1; // apply ease-out function
        var count = Math.floor(from + progress * (to - from)); // calculate current count based on progress
        el.innerHTML = formatNumber(count);

        if (rawProgress < 1) {
            requestAnimationFrame(updateCounter); // continue the animation if it's not done yet
        }
    }

    updateCounter();
};

const setInfinity = (el) => {
    const infinitySVG = `
                            <svg width="79" height="51" viewBox="0 0 79 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M76.5022 12.1944C74.808 8.38889 72.3085 5.38889 69.1007 3.19444C65.9068 1.09722 62.0047 0 57.6027 0C52.798 0 49.007 1.40278 46.0075 4.19444C43.1053 7 41.0084 10.3889 39.8142 14.5972H39.2032C38.0089 10.4028 35.8982 7 33.0098 4.19444C30.0104 1.38889 26.2055 0 21.4146 0C17.0126 0 13.1105 1.09722 9.91666 3.19444C6.72278 5.38889 4.22322 8.38889 2.51519 12.1944C0.821039 16.0972 0.015625 20.5 0.015625 25.5C0.015625 30.5 0.821039 35 2.51519 38.8056C4.20933 42.7083 6.70889 45.7083 9.91666 47.8055C13.1105 50 17.0126 51 21.4146 51C26.2194 51 30.0104 49.6944 33.0098 46.9028C35.9121 44.0972 38.0089 40.5972 39.2032 36.4028H39.8142C41.0084 40.5972 43.1192 44.0972 46.0075 46.9028C49.007 49.7083 52.8119 51 57.6027 51C62.0047 51 65.9068 50 69.1007 47.8055C72.2946 45.7083 74.7941 42.7083 76.5022 38.8056C78.1963 35 79.0017 30.5 79.0017 25.5C79.0017 20.5 78.1963 16.0972 76.5022 12.1944ZM31.6906 37.6944C29.2883 40.6944 25.8861 42.1944 21.3869 42.1944C16.8877 42.1944 13.4855 40.6944 11.0831 37.5972C8.68077 34.5972 7.48653 30.5 7.48653 25.5C7.48653 20.5 8.68077 16.5 11.0831 13.4028C13.4855 10.4028 16.9849 8.80556 21.3869 8.80556C25.7889 8.80556 29.2883 10.4028 31.6906 13.4028C34.093 16.4028 36.1065 20.5 36.1065 25.5C36.1065 30.5 34.093 34.6944 31.6906 37.6944ZM67.8926 37.5972C65.4902 40.6944 61.9908 42.1944 57.5888 42.1944C53.1868 42.1944 49.6874 40.6944 47.2851 37.6944C44.8827 34.6944 42.8692 30.5972 42.8692 25.5C42.8692 20.4028 44.8827 16.4028 47.2851 13.4028C49.6874 10.4028 53.0896 8.80556 57.5888 8.80556C62.088 8.80556 65.4902 10.4028 67.8926 13.4028C70.2949 16.5 71.4892 20.5 71.4892 25.5C71.4892 30.5 70.2949 34.5972 67.8926 37.5972Z" fill="#0E121E"/>
                            </svg>
                        `;
    el.innerHTML = infinitySVG;

    var startTime = performance.now();

    function updateRotation() {
        var now = performance.now();
        var rawProgress = (now - startTime) / duration; // calculate raw progress as a fraction between 0 and 1
        var progress = rawProgress < 1 ? 1 - (1 - rawProgress) * (1 - rawProgress) : 1; // apply ease-out function

        // Adjust rotation speed here
        var rotationDegree = 900 * progress;
        el.querySelector('svg').style.transform = `rotateX(${rotationDegree}deg)`;

        if (rawProgress < 1) {
            requestAnimationFrame(updateRotation); // continue the animation if it's not done yet
        }
    }

    updateRotation();
};

const initCountUp = () => {
    const options = {
        root: null, // Use the viewport as the root
        threshold: 0.8 // Trigger when 80% of the element is visible
    };
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                let el = entry.target;

                if (el.dataset.from == 'infinity') {
                    setInfinity(el);
                } else {
                    countUp(el, Number(el.dataset.from), Number(el.dataset.to));
                }

                observer.unobserve(entry.target);
            }
        });
    }, options);

    document.querySelectorAll('.js-count-up').forEach((el) => {
        observer.observe(el);
    });
};

export { initCountUp }
