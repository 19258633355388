const initItemsLoader = () => {

    const block = document.querySelector(".js-loader");
    if(!block) return;

    const button = document.querySelector(".js-loader__button");
    const items  = document.querySelectorAll(".js-loader__item");

    const settings = JSON.parse(block.dataset.settings || '{}');
    const show  = settings.show_items;
    const load  = settings.load_items;
    const total = settings.total;

    const loadMore = (displayed, loaded) => {
        if (total - displayed <= 0) button.classList.add("hidden");

        button.addEventListener("click", () => {
            for(let i = 0; i < (displayed + loaded); i++) {
                if(items[i]) items[i].classList.add("is-active");
            }
            displayed += loaded;
            if(displayed >= total) button.classList.add("hidden");
        });
    };

    loadMore(show, load);
};

export { initItemsLoader }
