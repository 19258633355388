import Glide from '@glidejs/glide';


const initBenefitsSliders = () => {

    const setupBlock = (block) => {

        const slider = block.querySelector(".glide");
        if (!slider) return;

        const texts    = block.querySelectorAll(".js-text");
        const pictures = block.querySelectorAll(".js-picture");
        const contents = block.querySelectorAll(".js-content");

        const updatePictures = (desiredState) => {
            if (!pictures) return;
            if (pictures.length < 1) return;
            pictures.forEach((element, index) => {
                if (index === desiredState) {
                    element.animate({ opacity: 1 }, { duration: 500 }).onfinish = () => {
                        element.classList.remove("opacity-0");
                    };
                } else {
                    element.animate({ opacity: 0 }, { duration: 500 }).onfinish = () => {
                        element.classList.add("opacity-0");
                    };
                }
            });
        }

        const updateTexts = (desiredState) => {
            if (!texts) return;
            if (texts.length < 1) return;
            texts.forEach((element, index) => {
                if (index === desiredState) {
                    element.animate({ opacity: 1 }, { duration: 500 }).onfinish = () => {
                        element.classList.remove("opacity-0");
                    };
                } else {
                    element.animate({ opacity: 0 }, { duration: 500 }).onfinish = () => {
                        element.classList.add("opacity-0");
                    };
                }
            });
        }
    
        const updateContent = (desiredState) => {
            if (!contents) return;
            if (contents.length < 1) return;
            contents.forEach((element, index) => {
                if (index === desiredState) {
                    element.classList.add("is-active");
                } else {
                    element.classList.remove("is-active");
                }
            });
        }

        let options = {
            type: 'slider',
            rewind: false,
            autoplay: false,
            touchRatio: 0.2,
            touchAngle: 30,
            swipeTreshold: 40,
            dragTreshold: 60,
            animationDuration: 1000,
            perView: 1,
            peek: { before: 51, after: 350 },
            gap: 51,
            breakpoints: {
                767: {
                    peek: { before: 35, after: 200 },
                    gap: 45,
                },
                639: {
                    peek: { before: 35, after: 80 },
                    gap: 45,
                },
            }
        }
    
        const glide = new Glide(slider, options);


        /* setup control arrows and buttons */

        const arrowsLeft  = block.querySelectorAll(".js-left");
        const arrowsRight = block.querySelectorAll(".js-right");

        if (arrowsLeft) {
            arrowsLeft.forEach(arrow => {
                arrow.addEventListener("click", () => {
                    glide.go(`<`);
                })
            })
        }

        if (arrowsRight) {
            arrowsRight.forEach(arrow => {
                arrow.addEventListener("click", () => {
                    glide.go(`>`);
                })
            })
        }

        if (contents) {
            contents.forEach((element, index) => {
                element.addEventListener('click', () => {
                    glide.go(`=${index}`);
                })
            });
        }



        glide.on('run', (event) => {

            updateTexts(glide.index);
            updatePictures(glide.index);
            updateContent(glide.index);
        })


        if (arrowsLeft && arrowsRight) {
            glide.on(['mount.before', 'run'], (event) => {

                let slidesCount = slider.querySelectorAll('.glide__slide').length;
    
                if (glide.index == 0) {
                    arrowsLeft.forEach(arrow => {
                        arrow.classList.add('is-disabled');
                    })
                }
                else {
                    arrowsLeft.forEach(arrow => {
                        arrow.classList.remove('is-disabled');
                    })
                }
    
                if (glide.index == slidesCount-1) {
                    arrowsRight.forEach(arrow => {
                        arrow.classList.add('is-disabled');
                    })
                }
                else {
                    arrowsRight.forEach(arrow => {
                        arrow.classList.remove('is-disabled');
                    })
                }
            })
        }


        /* change slider cursor */

        slider.addEventListener('mousedown', () => { slider.style.cursor = 'grabbing' })
        slider.addEventListener('mouseup', () => { slider.style.cursor = 'grab' })
        slider.addEventListener('mouseenter', () => { slider.style.cursor = 'grab' })

        /* mount */

        glide.mount();
    }

    let blocks = document.querySelectorAll(".js-benefits-slider");

    if (!blocks) return;
    
    blocks.forEach(block => {
        setupBlock(block);
    });
    
}

export { initBenefitsSliders }
