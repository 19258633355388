import { freezeBody, unfreezeBody } from './utils';

const initCopyLightboxes = () => {

    const setupBlock = (block) => {

        const popup = block.querySelector(".js-lightbox");
        if (!popup) return;

        const opener  = block.querySelector(".js-lightbox-opener");
        const closer  = popup.querySelector('.js-lightbox-closer');
        const content = popup.querySelector('.js-lightbox-content');

        
        opener.addEventListener('click', () => {
            popup.classList.add('is-active');
            freezeBody();
        });

        closer.addEventListener('click', () => {
            popup.classList.remove('is-active');
            unfreezeBody();
        });

        popup.addEventListener('click', (e) => {
            if(!content.contains(e.target)) {
                popup.classList.remove('is-active');
                unfreezeBody();
            };
        });
    } 

    let blocks = document.querySelectorAll(".js-copy-lightbox");

    if (!blocks) return;
    
    blocks.forEach(block => {
        setupBlock(block);
    });
};

export { initCopyLightboxes }