import { freezeBody, unfreezeBody } from '../utils';

/* sticky menu  */
const menu = document.querySelector('header');
const scroll = () => {
    const offsetY = window.offsetY || window.pageYOffset

    if (offsetY > menu.getBoundingClientRect().height / 2) {
        menu.classList.add('stick')
    } else {
        menu.classList.remove('stick')
    }
}

const setupStickyMenu = () => {
    let prevScrollPos = window.pageYOffset || document.documentElement.scrollTop

    window.addEventListener('scroll', function () {
        const currentScrollPos =
            window.pageYOffset || document.documentElement.scrollTop

        if (
            currentScrollPos > menu.getBoundingClientRect().height / 2 &&
            currentScrollPos > prevScrollPos
        ) {
            menu.classList.add('is-away')
        } else {
            menu.classList.remove('is-away')
        }

        prevScrollPos = currentScrollPos
    })
}


/* dropdowns in sticky menu */
function setupDropdownMenus() {
    let dropdowns = document.querySelectorAll(".js-menu-dropdown");
    dropdowns.forEach(dropdown => {
        let closeTimeout;
        // Open the dropdown on mouse enter
        dropdown.addEventListener('mouseenter', () => {
            clearTimeout(closeTimeout); // Clear any existing timeout
            dropdown.classList.add('is-open');
        });

        // Start a timeout to close the dropdown on mouse leave
        dropdown.addEventListener('mouseleave', () => {
            closeTimeout = setTimeout(() => {
                dropdown.classList.remove('is-open');
            }, 800); // 2 seconds delay
        });

        window.addEventListener('click', (e) => {
            if (!dropdown.contains(e.target)) {
                dropdown.classList.remove('is-open');
            }
        })
    });

    function closeDropdowns() {
        dropdowns.forEach(element => {
            element.classList.remove('is-open');
        });
        ; // Clear any existing timeout when closing all dropdowns
        if (typeof closeTimeout !== 'undefined') {
            clearTimeout(closeTimeout);

        }
    }

    // Close all dropdowns on scroll
    window.addEventListener('scroll', closeDropdowns, { passive: true });
}

const initStickyMenu = () => {
    scroll()
    window.addEventListener('scroll', () => scroll())
    setupStickyMenu()
    setupDropdownMenus()
}

export { initStickyMenu }