import Glide from '@glidejs/glide';


const initProcessSliders = () => {

    const setupBlock = (block) => {

        const slider = block.querySelector(".glide");
        if (!slider) return;

        const pictures = block.querySelectorAll(".js-picture");
        const contents = block.querySelectorAll(".js-content");

        const updatePictures = (desiredState) => {
            if (!pictures) return;
            if (pictures.length < 1) return;
            pictures.forEach((element, index) => {
                if (index === desiredState) {
                    element.animate({ opacity: 1 }, { duration: 500 }).onfinish = () => {
                        element.classList.remove("opacity-0");
                    };
                } else {
                    element.animate({ opacity: 0 }, { duration: 500 }).onfinish = () => {
                        element.classList.add("opacity-0");
                    };
                }
            });
        }
    
        const updateContent = (desiredState) => {
            if (!contents) return;
            if (contents.length < 1) return;
            contents.forEach((element, index) => {
                if (index === desiredState) {
                    element.classList.add("is-active");
                } else {
                    element.classList.remove("is-active");
                }
            });
        }


        let options = {
            type: 'slider',
            rewind: false,
            autoplay: false,
            touchRatio: 0.2,
            touchAngle: 30,
            swipeTreshold: 40,
            dragTreshold: 60,
            animationDuration: 500,
            perView: 1,
            peek: 0,
            gap: 30,
            breakpoints: { /*
                1023: {
                    animationDuration: 1000,
                    perView: 2,
                    peek: { before: 50, after: 200 },
                    gap: 50,
                },
                850: {
                    animationDuration: 1000,
                    perView: 1,
                    peek: { before: 45, after: 250 },
                    gap: 45,
                },
                640: {
                    animationDuration: 1000,
                    perView: 1,
                    peek: { before: 35, after: 150 },
                    gap: 35,
                },
                450: {
                    animationDuration: 1000,
                    perView: 1,
                    peek: { before: 35, after: 75 },
                    gap: 35,
                },
                400: {
                    animationDuration: 1000,
                    perView: 1,
                    peek: { before: 35, after: 60 },
                    gap: 35,
                } */
            }
        }
    
        const glide = new Glide(slider, options);

        /* setup control arrows and buttons */

        const arrowLeft  = block.querySelector(".js-left");
        const arrowRight = block.querySelector(".js-right");

        if (arrowLeft) {
            arrowLeft.addEventListener("click", () => {
                glide.go(`<`);
            })
        }

        if (arrowRight) {
            arrowRight.addEventListener("click", () => {
                glide.go(`>`);
            })
        }

        if (contents) {
            contents.forEach((element, index) => {
                element.addEventListener('click', () => {
                    glide.go(`=${index}`);
                })
            });
        }

        glide.on('run', (event) => {

            updatePictures(glide.index);
            updateContent(glide.index);
        })

        if (arrowLeft && arrowRight) {
            glide.on(['mount.before', 'run'], (event) => {

                let slidesCount = slider.querySelectorAll('.glide__slide').length;
    
                if (glide.index == 0) {
                    arrowLeft.classList.add('is-disabled');
                }
                else {
                    arrowLeft.classList.remove('is-disabled');
                }
    
                if (glide.index == slidesCount-1) {
                    arrowRight.classList.add('is-disabled');
                }
                else {
                    arrowRight.classList.remove('is-disabled');
                }
            })
        }



        /* change slider cursor */

        slider.addEventListener('mousedown', () => { slider.style.cursor = 'grabbing' })
        slider.addEventListener('mouseup', () => { slider.style.cursor = 'grab' })
        slider.addEventListener('mouseenter', () => { slider.style.cursor = 'grab' })

        /* mount */

        glide.mount();
    }

    let blocks = document.querySelectorAll(".js-process-slider");

    if (!blocks) return;
    
    blocks.forEach(block => {
        setupBlock(block);
    });
    
}

export { initProcessSliders }
