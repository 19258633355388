
const initCursorTracker = () => {

    let blocks = document.querySelectorAll('.js-cursor');
    if (!blocks) return;

    const setupCursorTracker = (block) => {
        const cursorWrapper = block;
        const cursorArea = cursorWrapper.querySelector('.js-cursor__area');
        const picture = cursorWrapper.querySelector('.js-cursor__content');
        if(!cursorWrapper || !cursorArea || !picture) return;

        cursorArea.addEventListener('mouseenter', (event) => {
            picture.style.opacity = 1;
        });

        cursorArea.addEventListener('mousemove', (event) => {
            const mouseX = (event.clientX - cursorArea.getBoundingClientRect().left) / cursorArea.offsetWidth;
            const mouseY = (event.clientY - cursorArea.getBoundingClientRect().top) / cursorArea.offsetHeight;

            // add delay or interpolation here for a smoother effect
            const movementSpeed = 0.7;
            const targetX = mouseX * cursorArea.offsetWidth;
            const targetY = mouseY * cursorArea.offsetHeight;
            const dx = (targetX - picture.offsetLeft) * movementSpeed;
            const dy = (targetY - picture.offsetTop) * movementSpeed;

            picture.style.left = `${picture.offsetLeft + dx + 10}px`;
            picture.style.top = `${picture.offsetTop + dy + 10}px`;
        });

        cursorArea.addEventListener('mouseleave', () => {
            picture.style.opacity = 0;
        });
    }

    blocks.forEach(block => {
        setupCursorTracker(block)
    });

}

export { initCursorTracker };
