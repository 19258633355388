/**
 * BaseTheme rework now uses javascript version ES6
 * ------
 *
 * Functions and Variables are no longer global and need to be imported
 * ( How to prepare function for export can be seen in `utils.js` )
 */
import { customEvents, initUtils } from './utils';
import { initStickyMenu } from './navigation/stickyMenu';
//import { initRolldownMenu } from './navigation/rolldownMenu'
import { initMobileMenu } from './navigation/mobileMenu';
import { initCursorTracker } from './cursor';
import { initCountUp } from './counter';
import { initMainSliders } from './glide-main';
import { initProcessSliders } from './glide-process';
import { initBenefitsSliders } from './glide-benefits';
import { initProfessionSliders } from './glide-profession';
import { initGallerySliders } from './glide-gallery';
import { initJobSliders } from './glide-job';
import { initItemsLoader } from './items-loader';
import { initLightbox } from './eu-lightbox';
import { initCopyLightboxes } from './copy-lightbox';

/**
 * This function or DOMContentLoaded event is necesary for compiling to work
 *
 * Standalone functions will be removed on compile, so function that is called only in onclick event for example will be removed
 */

function initAllScripts() {
    initUtils();
    initStickyMenu();
    initMobileMenu();
    initCursorTracker();
    initCountUp();
    initMainSliders();
    initProcessSliders();
    initBenefitsSliders();
    initProfessionSliders();
    initGallerySliders();
    initJobSliders();
    initItemsLoader();
    initLightbox();
    initCopyLightboxes();
}

if (document.readyState !== "loading") {
    initAllScripts();
} else {
    document.addEventListener("DOMContentLoaded", function () {
        initAllScripts();
    });
}

