import Glide from '@glidejs/glide';

const initMainSliders = () => {
    const setupBlock = (block) => {
        const handleSwipe = (block, glide) => {
            let touchStartX = 0;
            let touchEndX = 0;
            let mouseStartX = 0;
            let mouseEndX = 0;
            let isDragging = false;

            // Minimum distance for swipe detection
            const minSwipeDistance = 50;

            // Touch events
            block.addEventListener('touchstart', (e) => {
                touchStartX = e.touches[0].clientX;
            });

            block.addEventListener('touchend', (e) => {
                touchEndX = e.changedTouches[0].clientX;
                const swipeDistance = touchEndX - touchStartX;

                if (Math.abs(swipeDistance) > minSwipeDistance) {
                    if (swipeDistance > 0) {
                        glide.go('>'); // Swipe right, go back
                    } else {
                        glide.go('<'); // Swipe left, go forward
                    }
                }
            });

            // Mouse events
            block.addEventListener('mousedown', (e) => {
                isDragging = true;
                mouseStartX = e.clientX;
                block.style.cursor = 'grabbing';
            });

            block.addEventListener('mousemove', (e) => {
                if (isDragging) {
                    block.style.cursor = 'grabbing';
                }
            });

            block.addEventListener('mouseup', (e) => {
                if (isDragging) {
                    mouseEndX = e.clientX;
                    const swipeDistance = mouseEndX - mouseStartX;

                    if (Math.abs(swipeDistance) > minSwipeDistance) {
                        if (swipeDistance > 0) {
                            glide.go('>'); // Swipe right, go back
                        } else {
                            glide.go('<'); // Swipe left, go forward
                        }
                    }
                }
                isDragging = false;
                block.style.cursor = 'grab';
            });

            block.addEventListener('mouseleave', () => {
                isDragging = false;
                block.style.cursor = 'grab';
            });
        };

        const slider = block.querySelector('.glide');
        if (!slider) return;

        const timer = +slider.dataset.timer * 1000;
        const pictures = block.querySelectorAll('.js-picture');
        const texts = block.querySelectorAll('.js-text');
        const buttons = block.querySelectorAll('.js-button');

        const updatePictures = (desiredState) => {
            if (!pictures) return;
            if (pictures.length < 1) return;
            pictures.forEach((element, index) => {
                if (index === desiredState) {
                    element.animate(
                        { opacity: 1 },
                        { duration: 500 }
                    ).onfinish = () => {
                        element.classList.remove('opacity-0');
                    };
                } else {
                    element.animate(
                        { opacity: 0 },
                        { duration: 500 }
                    ).onfinish = () => {
                        element.classList.add('opacity-0');
                    };
                }
            });
        };

        const updateTexts = (desiredState) => {
            if (!texts) return;
            if (texts.length < 1) return;
            texts.forEach((element, index) => {
                if (index === desiredState) {
                    element.animate(
                        { opacity: 1 },
                        { duration: 500 }
                    ).onfinish = () => {
                        element.classList.remove('opacity-0');
                        element.classList.add('z-20');
                    };
                } else {
                    element.animate(
                        { opacity: 0 },
                        { duration: 500 }
                    ).onfinish = () => {
                        element.classList.add('opacity-0');
                        element.classList.remove('z-20');
                    };
                }
            });
        };

        const updateButtons = (desiredState) => {
            if (!buttons) return;
            buttons.forEach((element, index) => {
                if (index === desiredState) {
                    element.classList.add('is-current');
                } else {
                    element.classList.remove('is-current');
                }
            });
        };

        const startProgressBar = () => {
            const progressBar = block.querySelector('.js-progressbar');
            if (!progressBar) return;

            progressBar.style.transition = 'none';
            progressBar.style.width = '0';

            requestAnimationFrame(() => {
                progressBar.style.transition = `width ${timer}ms linear`;
                progressBar.style.width = '102%';
            });
        };

        let options = {
            type: 'carousel',
            autoplay: timer,
            hoverpause: false,
            touchRatio: 0.2,
            touchAngle: 30,
            swipeTreshold: 40,
            dragTreshold: 60,
            animationDuration: 10,
            perView: 1,
            peek: 0,
            gap: 0,
            breakpoints: {
                //
            },
        };

        const glide = new Glide(slider, options);

        /* setup control arrows and buttons */

        /*
        const arrowLeft  = block.querySelector(".js-left");
        const arrowRight = block.querySelector(".js-right");

        if (arrowLeft) {
            arrowLeft.addEventListener("click", () => {
                glide.go(`<`);
            })
        }

        if (arrowRight) {
            arrowRight.addEventListener("click", () => {
                glide.go(`>`);
            })
        }
        */

        if (buttons) {
            buttons.forEach((element, index) => {
                element.addEventListener('click', () => {
                    glide.go(`=${index}`);
                });
            });
        }

        glide.on('run', (event) => {
            updatePictures(glide.index);
            updateTexts(glide.index);
            updateButtons(glide.index);
            startProgressBar();
        });

        glide.on('mount.before', (event) => {
            startProgressBar();
            handleSwipe(block, glide);
        });

        /* change slider cursor */

        slider.addEventListener('mousedown', () => {
            slider.style.cursor = 'grabbing';
        });
        slider.addEventListener('mouseup', () => {
            slider.style.cursor = 'grab';
        });
        slider.addEventListener('mouseenter', () => {
            slider.style.cursor = 'grab';
        });

        /* mount */

        glide.mount();
    };

    let blocks = document.querySelectorAll('.js-main-slider');

    if (!blocks) return;

    blocks.forEach((block) => {
        setupBlock(block);
    });
};

export { initMainSliders };
