import Glide from '@glidejs/glide';
import { freezeBody, unfreezeBody } from './utils';

const initGallerySliders = () => {

    const setupBlock = (block) => {

        const slider = block.querySelector(".glide");
        if (!slider) return;

        const lightbox = block.querySelector('.js-slider-lightbox');
        const pictureWrapper = block.querySelector(".js-picture-wrapper");
        const pictures = block.querySelectorAll(".js-slider-picture");
        const openers = block.querySelectorAll(".js-slider-opener");
        const closer = block.querySelector('.js-slider-closer');

        const updatePictures = (desiredState) => {
            if (!pictures) return;
            if (pictures.length < 1) return;
            pictures.forEach((element, index) => {
                if (index === desiredState) {
                    element.animate({ opacity: 1 }, { duration: 300 }).onfinish = () => {
                        element.classList.remove("opacity-0");
                    };
                } else {
                    element.animate({ opacity: 0 }, { duration: 300 }).onfinish = () => {
                        element.classList.add("opacity-0");
                    };
                }
            });
        }

        let options = {
            type: 'carousel',
            autoplay: false,
            touchRatio: 0.2,
            touchAngle: 30,
            swipeTreshold: 40,
            dragTreshold: 60,
            animationDuration: 10,
            perView: 1,
            peek: 0,
            gap: 0,
            breakpoints: {
                767: {
                    animationDuration: 500,
                }
            }
        }
    
        const glide = new Glide(slider, options);

        /* setup control arrows and buttons */

        const arrowLeft  = block.querySelector(".js-left");
        const arrowRight = block.querySelector(".js-right");

        if (arrowLeft) {
            arrowLeft.addEventListener("click", () => {
                glide.go(`<`);
            })
        }

        if (arrowRight) {
            arrowRight.addEventListener("click", () => {
                glide.go(`>`);
            })
        }

        if (openers) {
            openers.forEach((element, index) => {
                element.addEventListener('click', () => {
                    glide.go(`=${index}`);
                    lightbox.classList.add('is-active');
                    freezeBody();
                })
            });
        }

        closer.addEventListener('click', () => {
            lightbox.classList.remove('is-active');
            unfreezeBody();
        });

        lightbox.addEventListener('click', (e) => {
            if(!arrowLeft.contains(e.target) && !arrowRight.contains(e.target) && !pictureWrapper.contains(e.target)) {
                lightbox.classList.remove('is-active');
                unfreezeBody();
            };
        });

        glide.on('run', (event) => {
            updatePictures(glide.index);
        })

        /* change slider cursor */

        slider.addEventListener('mousedown', () => { slider.style.cursor = 'grabbing' })
        slider.addEventListener('mouseup', () => { slider.style.cursor = 'grab' })
        slider.addEventListener('mouseenter', () => { slider.style.cursor = 'grab' })

        /* mount */

        glide.mount();
        
    }

    let blocks = document.querySelectorAll(".js-gallery-slider");

    if (!blocks) return;
    
    blocks.forEach(block => {
        setupBlock(block);
    });
    
}

export { initGallerySliders }
