import { freezeBody, unfreezeBody } from './utils';

const initLightbox = () => {

    const popup = document.querySelector(".js-eu-popup");
    if (!popup) return;

    const openers = document.querySelectorAll(".js-eu-popup-opener");
    const closer  = popup.querySelector('.js-popup-closer');
    const content = popup.querySelector('.js-popup-content');

    if (openers) {
        openers.forEach((opener) => {
            opener.addEventListener('click', () => {
                popup.classList.add('is-active');
                freezeBody();
            })
        });
    }

    closer.addEventListener('click', () => {
        popup.classList.remove('is-active');
        unfreezeBody();
    });

    popup.addEventListener('click', (e) => {
        if(!content.contains(e.target)) {
            popup.classList.remove('is-active');
            unfreezeBody();
        };
    });
};

export { initLightbox }